:root {
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    --p: 2rem;
    }

* {
  box-sizing: border-box;
  margin: 0;
  }

.App {
    max-width: 600px;
    margin: 0 auto;
    padding: var(--p);
}

.App > * + * {
margin-top: var(--p);
}

article > * + * {
margin-top: .5rem;
}

article header {
display: flex;
justify-content: space-between;
align-items: flex-end;
position: relative;
}

article header .copy {
    display: block;
    position: absolute;
    right: 100%;
    font-size: 1.25rem;
    padding: 0 .25rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    margin-top: -2.5px;
}


article header:hover .copy {
    opacity: .5;
}

article header .copy:hover {
    opacity: 1;
}